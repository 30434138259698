import { BubblesLayout } from 'layouts/bubblesLayout/bubblesLayout';
import { useIndexPageHook } from 'pageHooks/indexHooks';
import * as pageClasses from 'pages/index.module.scss';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { buildCdnImageUrl } from 'services/url';

const IndexPage: React.FC = () => {
  const { downloadButtons } = useIndexPageHook();

  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>ScheduleLab - Scheduling down to a science!</title>
        <meta name="description" content="ScheduleLab is the premiere scheduling app for college students!" />
      </Helmet>

      <BubblesLayout>
        <article className={pageClasses.pageContent}>
          <section className={pageClasses.hero}>
            <section className={pageClasses.heroDetails}>
              <h1 className={pageClasses.heroText}>Making the perfect schedule is hard... Well, not anymore!</h1>
              <section className={pageClasses.heroBadges}>
                {downloadButtons.map((downloadButton, index) => (
                  <a key={index} href={downloadButton.downloadLink} target="_blank" rel="noopener noreferrer">
                    <img
                      className={pageClasses.heroBadge}
                      src={downloadButton.imageSrc}
                      alt={downloadButton.imageAlt}
                    />
                  </a>
                ))}
              </section>
            </section>
            <img
              className={pageClasses.heroScreenshot}
              src={buildCdnImageUrl('/demo/try-schedulelab/iphone-12-full-schedule.png')}
              alt="Full Schedule on Apple iPhone"
            />
          </section>
          <section className={pageClasses.contentCard}>
            <section className={pageClasses.cardSection}>
              <h2 className={pageClasses.cardTitle}>What is ScheduleLab?</h2>
              <hr className={pageClasses.cardSeparator} />
              <p>
                Every student has a registration story: Finding out a class is full, meeting with their advisors, and
                staying up late just to work on backup plans. Often it’s a matter of trial and error to find the perfect
                schedule - spending time mixing and matching courses to find ones that fit together.
              </p>
              <p>
                Thankfully, our team at ScheduleLab, LLC team has designed ScheduleLab - an awesome app that finally
                solves the problem once and for all. ScheduleLab’s lightning-fast algorithm is able to take users’ need
                and calculate the best choices available to you. Need a day off? Want to have your favorite professor?
                No problem! Just enter your preferences into the app and we’ll try to match them!
              </p>
              <p>
                Try ScheduleLab for your upcoming registration season, and see how you can bring scheduling down to a
                science!
              </p>
            </section>
            <section className={pageClasses.cardSection}>
              <h2 className={pageClasses.cardTitle}>Up-to-Date Catalogs</h2>
              <p>
                Choose from an ever-expanding list of supported colleges, down to the campus. ScheduleLab keeps it’s
                data relevant, so you don’t have to worry about last-minute catalog changes. Don’t see your school? You
                can request it right in the app.
              </p>
            </section>
            <section className={pageClasses.cardSection}>
              <h2 className={pageClasses.cardTitle}>Students Love Us</h2>
              <p>
                ScheduleLab is able to cut down registration preparation from hours down to minutes, giving students the
                time they need to take care of the things that really matter.
              </p>
            </section>
            <section className={pageClasses.cardSection}>
              <h2 className={pageClasses.cardTitle}>Advisors Love Us</h2>
              <p>
                ScheduleLab’s speed enables advisors to help students with registration issues faster - perfect for
                helping a high volume of students figure out their next steps when their plans fall through.
              </p>
            </section>
            <section className={`${pageClasses.cardSection} ${pageClasses.contactUsSection}`}>
              <section className={pageClasses.contactUsText}>
                <p>
                  Our team is continuously looking for new ways to improve our product and the ScheduleLab ecosystem.
                </p>
                <p>If you’d like to see how ScheduleLab can help your school, use the link below to get in touch!</p>
              </section>
              <a className={`button-green`} href="mailto:support@schedulelab.io">
                Contact Us
              </a>
            </section>
          </section>
        </article>
      </BubblesLayout>
    </main>
  );
};

export default IndexPage;
