import { buildCdnImageUrl } from 'services/url';

type UseIndexPageHookReturn = {
  downloadButtons: {
    downloadLink: string;
    imageSrc: string;
    imageAlt: string;
  }[];
};

export function useIndexPageHook(): UseIndexPageHookReturn {
  return {
    downloadButtons: [
      {
        downloadLink: 'https://apps.apple.com/us/app/schedulelab/id1554703951',
        imageSrc: buildCdnImageUrl('/badge/app-store-badge.svg'),
        imageAlt: 'Download on the App Store',
      },
      {
        downloadLink: 'https://play.google.com/store/apps/details?id=io.schedulelab.schedulelabforcolleges',
        imageSrc: buildCdnImageUrl('/badge/google-play-badge.svg'),
        imageAlt: 'Get it on Google Play',
      },
    ],
  };
}
